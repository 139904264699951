// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const SEARCH_FORM   = document.querySelector(".search-form");
const SEARCH_INPUT  = SEARCH_FORM ? SEARCH_FORM.querySelector(".search-form__input[name=s]") : false;
const SEARCH_RADIOS = SEARCH_FORM ? SEARCH_FORM.querySelectorAll(".search-form__input[name=source]") : false;

if (SEARCH_INPUT && SEARCH_RADIOS.length === 2) {

    SEARCH_RADIOS.forEach((radio) => {
        if (window.innerWidth >= 768 && radio.checked) {
            SEARCH_INPUT.setAttribute("placeholder", radio.dataset.placeholder);
        }

        radio.addEventListener("change", () => {
            if (window.innerWidth >= 768) {
                SEARCH_INPUT.setAttribute("placeholder", radio.dataset.placeholder);
            }
        });
    });
}

const MODES = document.querySelectorAll(".search-form input[name=mode]");

MODES.forEach((mode) => {
    mode.addEventListener("change", () => {
        if (mode.value === "catalog") {
            mode.closest(".search-form").setAttribute("target", "_blank");
        } else {
            mode.closest(".search-form").setAttribute("target", "_self");
        }
    }, { passive: true });
});
