// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import Swiper from "swiper";

// init swiper
new Swiper (".swiper-container--slideshow", {
    autoplay: {
        delay: 5000,
    },
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    speed: 150,
});
